import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Button, TextField, Typography, Select } from '@material-ui/core'
import StateSelector from '../../ui/StateSelector'
import CountySelector from '../../ui/countySelector'
import Mutation from 'react-apollo/Mutation'
import { useMutation } from '@apollo/react-hooks'
import {
	GET_USER_PROPERTIES,
	useGetPaymentMethod
} from '../../../apollo/queries'
import {
	CREATE_PROPERTY,
	UPDATE_SELECTED_PROPERTY_ID
} from '../../../apollo/mutations'
import { EditCard } from '../../../components/settings'
import Swal from 'sweetalert2'
import Loading from '../../ui/Loading'
import WaitButton from '../../ui/WaitButton'
import {
	Promo,
	useAdminCreatePromoMutation,
	UserRole
} from '../../../apollo/generated'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import LuxonUtils from '@date-io/luxon'

const nonNumRegex = /\D/g
const numberMaxLen = maxLen => value =>
	value.slice(0, maxLen).replace(nonNumRegex, '')

interface Props {
	onClose: (success?: boolean) => void
}

const CreatePromoModal: FunctionComponent<Props> = ({ onClose }: Props) => {
	const [code, setCode] = useState('')
	const [startingCredits, setStartingCredits] = useState('50')
	const [zipCodes, setZipCodes] = useState('')
	const [userType, setUserType] = useState('')
	const [expiresAt, setExpiresAt] = useState<Date | null>(null)
	const [percentageDiscount, setPercentageDiscount] = useState(10)

	const [createPromo, { loading }] = useAdminCreatePromoMutation()

	const submit = async () => {
		try {
			await createPromo({
				variables: {
					code,
					startingCredits: parseInt(startingCredits),
					zipCodes: zipCodes.split(',').map(z => z.trim()),
					userType: userType as UserRole,
					expiresAt,
					percentageDiscount,
				}
			})
			onClose(true)
		} catch (e) {
			Swal.fire('Error Creating Promo', e.message, 'error')
		}
	}

	return (
		<StyledForm
			onSubmit={e => {
				e.preventDefault()
				submit()
			}}
		>
			<Typography align="center" variant="h6">
				New Promo
			</Typography>
			<Fields>
				<Field
					required
					label="Code"
					value={code}
					onChange={e => setCode(e.target.value)}
				/>
				<Field
					required
					label="Starting Credits"
					value={startingCredits}
					type="number"
					onChange={e => setStartingCredits(e.target.value)}
				/>
				<Field
					label="Zip Codes (Comma Separated)"
					value={zipCodes}
					onChange={e => setZipCodes(e.target.value)}
				/>
				<Field
					label="Percentage discount"
					value={percentageDiscount}
					type={'number'}
					onChange={e => setPercentageDiscount(parseInt(e.target.value))}
				/>
				<Select
					value={userType}
					onChange={e => setUserType(e.target.value)}
					placeholder="User Type"
				>
					<option value="">User Type</option>
					<option value="HOMEOWNER">Homeowner</option>
					<option value="CONTRACTOR">Contractor</option>
				</Select>
				<MuiPickersUtilsProvider utils={LuxonUtils}>
					<DatePicker
						label="Expiration Date (Optional)"
						disablePast
						autoOk
						value={expiresAt}
						onChange={newDate => setExpiresAt(newDate)}
						format="MM/dd/yyyy"
					/>
				</MuiPickersUtilsProvider>
			</Fields>
			<SubmitButton inProgress={loading}>Submit</SubmitButton>
		</StyledForm>
	)
}

export default CreatePromoModal

const SubmitButton = styled(WaitButton).attrs({
	buttonProps: { type: 'submit' }
})`
	justify-content: center;
	margin-top: 24px;
	padding: 6px 18px;
	@media (min-width: 780px) {
		align-self: center;
	}
`

const Fields = styled.div`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Field = styled(TextField).attrs({
	variant: 'outlined',
	margin: 'dense'
})`
	&& {
		margin-left: 12px;
		margin-right: 12px;
		min-width: 84px;
		margin-top: 12px;
	}
` as typeof TextField

const StateField = styled(StateSelector).attrs({
	variant: 'outlined',
	margin: 'dense'
})`
	&& {
		margin-left: 12px;
		margin-right: 12px;
		min-width: 84px;
		margin-top: 12px;
	}
` as typeof StateSelector

const StyledForm = styled.form`
	background-color: white;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const BreakContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 780px) {
		flex-direction: column;
		align-items: stretch;
		> * {
			width: Calc(100% - 24px) !important;
		}
	}
`
