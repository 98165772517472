import gql from 'graphql-tag'
import { useQuery, QueryHookOptions } from '@apollo/react-hooks'
import { ContractorJobFragmentFragmentDoc as ContractorJobFragment } from '../generated'
import type { Contractor, ContractorJob, TradeGroup, User } from '../generated'

export const GET_CONTRACTOR_JOBS = gql`
	query GetContractorJobs(
		$page: Int!
		$view: String!
		$trades: [TradeGroup!]
		$jobId: ID
	) {
		contractor {
			id
			trades {
				id
				group
			}
		}
		contractorJobs(page: $page, view: $view, trades: $trades, jobId: $jobId) {
			...ContractorJobFragment
		}
		contractorOngoingJobsCount(trades: $trades)
		contractorPendingBidsCount(trades: $trades)
		contractorBiddableJobsCount(trades: $trades)
		contractorClosedJobsCount(trades: $trades)
	}
	${ContractorJobFragment}
`

export interface GetContractorJobs {
	contractorJobs: ContractorJob[]
	contractor: { trades: Readonly<Contractor['trades']> }
	contractorOngoingJobsCount: number
	contractorPendingBidsCount: number
	contractorBiddableJobsCount: number
	contractorClosedJobsCount: number
	user?: User
}

interface GetContractorJobsVariables {
	jobId?: string | null
	page: number
	view: 'OPEN' | 'PENDING' | 'BIDDABLE' | 'CLOSED'
	trades?: TradeGroup[] | null
}

export function useGetContractorJobs(
	options?: QueryHookOptions<GetContractorJobs, GetContractorJobsVariables>
) {
	return useQuery<GetContractorJobs, GetContractorJobsVariables>(
		GET_CONTRACTOR_JOBS,
		{ fetchPolicy: 'network-only', ...options }
	)
}
