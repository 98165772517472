import gql from "graphql-tag";
import { useQuery } from '@apollo/react-hooks'
import { Homeowner, QueryOptions } from '../types';
import { HomeownerJobFragmentFragmentDoc as HomeownerJobFragment, PropertySpecsFragmentFragmentDoc as PropertySpecsFragment } from '../generated'

export const ADMIN_GET_JOBS = gql`
	query AdminGetJobs($page: Int!, $view: String!, $search: String, $jobType: [String!], $county: String) {
		adminGetJobs (page: $page, view: $view, search: $search,jobType: $jobType,county: $county,) {
			...HomeownerJobFragment
			property {
				renoworksId
				legacyHomeSpecId
				houseNumber
				streetName
				city
				state
				zipCode
				owner {
					firstName
					lastName
					user {
						email
					}
				}
			}
		}
		adminGetJobsCount(page: $page, search: $search, jobType: $jobType, county: $county)
		adminGetSlippingJobsCount(page: $page, search: $search, jobType: $jobType, county: $county)
		adminGetBiddingJobsCount(page: $page, search: $search, jobType: $jobType, county: $county)
		adminGetOngoingJobsCount(page: $page, search: $search, jobType: $jobType, county: $county)
		adminGetCompletedJobsCount(page: $page, search: $search, jobType: $jobType, county: $county)
	}
	${HomeownerJobFragment}
`

export const GET_USER_PROPERTIES = gql`
	query GetUserProperties {
		homeowner {
			id
			firstName
			lastName
			user {
				id
				email
				createdAt
			}
			properties {
				id
				streetName
				houseNumber
				ready
				renoworksId
				renoworksDisplayId
				legacyHomeSpecId
				jobs { ...HomeownerJobFragment }
				propertySpec { ...PropertySpecsFragment }
				newViewer

			}
		}
	}
	${HomeownerJobFragment}
	${PropertySpecsFragment}
`;

interface GetUserProperties {
	homeowner: Homeowner
}

export function useGetUserProperties(options?: QueryOptions) {
	return useQuery<GetUserProperties, {}>(GET_USER_PROPERTIES, options && { ...options });
}
