import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { MonthlyCharge } from '../generated';
import { QueryOptions } from "../types"

export const GET_MONTHLY_CHARGES = gql`
	query GetMonthlyCharges {
      monthlyCharges {
          charges {
              id
              address
              amount
              period
              displayStatus
          }
          upcomingCharges {
              id
              address
              amount
              period
              displayStatus
          }
          hiredBidCharges {
              bidId
              jobTitle
              customerName
              amount
              lastChargeDate
              status
              displayStatus
          }
          pendingBidCharges {
              bidId
              jobTitle
              customerName
              amount
              lastChargeDate
              status
              displayStatus
          }
      }
  }
`;

export interface GetMonthlyCharges {
  monthlyCharges: MonthlyCharge
}


export function useGetMonthlyCharges(options?: QueryOptions) {
	return useQuery<GetMonthlyCharges, {}>(GET_MONTHLY_CHARGES, { fetchPolicy: 'network-only', ...options });
}
