import gql from "graphql-tag";
import { useQuery } from '@apollo/react-hooks'
import { User, QueryOptions } from '../types';
import { UserFragmentFragmentDoc as UserFragment } from '../generated'

export const GET_USER = gql`
	query GetUser {
		user { ...UserFragment }
	}
	${UserFragment}
`;

export interface GetUser {
	user: Readonly<User>
}

export function useGetUser(options?: QueryOptions) {
	return useQuery<GetUser, {}>(GET_USER, { fetchPolicy: "network-only", ...options });
}
