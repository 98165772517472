import React from 'react'
import { TextField, MenuItem } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'

import { useQuery } from "@apollo/react-hooks"
import { GET_STATES_WITH_COUNTIES } from "../../apollo/queries"
import Loading from './Loading';


const StateSelector = (props: TextFieldProps) => {
	const {
		data: { states } = {}, loading
	} = useQuery(GET_STATES_WITH_COUNTIES)
	if (loading) return <Loading dark />;
	return (
		<TextField {...props} select label="State" placeholder="State">
			<MenuItem value="">
				<em>State</em>
			</MenuItem>
			{states.map(s => (
				<MenuItem key={s.name} value={s.name}>
					{s.name}
				</MenuItem>
			))}
		</TextField>
	)
}

export default StateSelector
