import gql from "graphql-tag";
import { useQuery } from '@apollo/react-hooks'
import { PaymentMethod, User, QueryOptions } from '../types';
import { UserFragmentFragmentDoc as UserFragment } from '../generated'

export const GET_PAYMENT_METHOD = gql`
	query GetPaymentMethod {
		user { ...UserFragment }
		paymentMethod {
			id
			redactedCard
			expMonth
			expYear
			fullName
			addressLine1
			addressLine2
			city
			state
			zipCode
			isDefault
		}
		chargeAmounts {
			newPropertyCharge {
				chargeFrequency
				amountInCents
			}
		}
	}
	${UserFragment}
`;

interface IGetCardDetails {
	paymentMethod: Readonly<Partial<PaymentMethod>>
}

export const GET_CARD_DETAILS = gql`
	query GetCardDetails {
		paymentMethod {
			redactedCard
			expMonth
			expYear
		}
	}
`
export function useGetCardDetails(options?: QueryOptions) {
	return useQuery<IGetCardDetails, {}>(GET_CARD_DETAILS, { fetchPolicy: "network-only", ...options });
}

export interface GetPaymentMethod {
	user: Readonly<Partial<User>>
	paymentMethod: Readonly<Partial<PaymentMethod>>
	chargeAmounts: Readonly<{
		newPropertyCharge: Readonly<{
			chargeFrequency: 'FREE' | 'ONE_TIME' | 'SUBSCRIPTION',
			amountInCents: number
		}>
	}>
}

export function useGetPaymentMethod(options?: QueryOptions) {
	return useQuery<GetPaymentMethod, {}>(GET_PAYMENT_METHOD, { fetchPolicy: "network-only", ...options });
}
