import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { State } from '../generated'
import { QueryOptions } from '../types';

export const GET_COUNTIES = gql`
	query GetCounties {
		states {
			id
			name
			counties(orderBy: name_ASC) {
				id
				name
			}
		}
	}
`;

export interface GetCounties {
  readonly states: State[]
}

export function useGetCounties(options?: QueryOptions) {
	return useQuery<GetCounties, {}>(GET_COUNTIES, options ? options : undefined);
}
