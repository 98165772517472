import React from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler';
import AuthGuard from '../../components/ui/AuthGuard';
import AdminSidebar from '../../components/adminSidebar';
import AdminPromos from '../../components/admin/Promos';

const AdminPromosScreen = () => (
	<DisastrousErrorHandler>
		<AuthGuard allowed="ADMIN">
	<AdminSidebar>
		<AdminPromos />
	</AdminSidebar>
		</AuthGuard>
	</DisastrousErrorHandler>
)

export default AdminPromosScreen;
