import gql from 'graphql-tag'
export { GET_CHARGES, useGetCharges } from './getCharges'
export { GET_MONTHLY_CHARGES, useGetMonthlyCharges } from './getMonthlyCharges'
export { useGetContractorDetailsQuery as useGetContractorDetails } from '../generated'
export { GET_CONTRACTOR_JOBS, useGetContractorJobs } from './getContractorJobs'
export { GET_COUNTIES, useGetCounties } from './getCounties'
export { GET_PAYMENT_METHOD, useGetPaymentMethod } from './getPaymentMethod'
export { GET_USER, useGetUser } from './getUser'
export { GET_USER_PROPERTIES, useGetUserProperties } from './getUserProperties'

export const GET_SELECTED_PROPERTY_ID = gql`
    {
        selectedPropertyId @client
    }
`;

export const GET_TRADES = gql`
    {
        trades { group, id }
    }
`;

export const GET_MODEL_RELATIONS = gql`
	{
		selectedOptions @client
		modelRelations {
			id
			subOptionId
			specName3dModel {
				id,
				name,
				materials
				reference
			}
		}
	}
`;

export const GET_STATES_WITH_COUNTIES = gql`
	{
		states {
			name
			counties {
				value: id
				label: name
			}
		}
	}
`;

export const GET_COUNTIES_SIMPLE = gql`
	{
		countiesWithState {
			id
			name
		}
	}
`;
