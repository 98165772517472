import {makeStyles} from "@material-ui/styles";
import React from 'react'

import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { Button, CardContent } from '@material-ui/core'

interface Props {
	open: boolean
	dark?: boolean
	onClose: () => void
	children?: React.ReactNode
	style?: any
	dialogProps?: Partial<DialogProps>
	closeButton?: boolean
	overflow?: string
}

const useStyles = makeStyles({
	button: props => ({
		color: props.dark ? 'white;' : 'inherit',
		position: "absolute",
		right: "2px",
		top: "2px",
		zIndex: 2,
		height: "36px",
		width: "36px",
		minWidth: "36px"
	})
})

const ResponsiveModal = ({ open, onClose, children, style, dialogProps, dark, closeButton = true, overflow }: Props) => {
	const classes = useStyles();
	return (
		<StyledDialog
			style={{ zIndex: 1000 }}
			maxWidth="md"
			{...dialogProps}
			open={open}
			onClose={e => {
				e.stopPropagation && e.stopPropagation()
				onClose()
			}}
			PaperProps={{ style: { backgroundColor: '#2c3e50' } }}
			onClick={e => e.stopPropagation()}
			overflow={overflow}
		>
			<ModalContent dark={dark} style={style}>
				{closeButton && (
					<Button dark={dark} onClick={onClose} className={classes.button}>
						<CloseIcon />
					</Button>
				)}
				{children}
			</ModalContent>
		</StyledDialog>
	)
}

export default ResponsiveModal


const StyledDialog = styled(Dialog)<{ overflow: string }> `
	> div:nth-child(2) > div {
		// overflow-y: ${props => props.overflow || "auto"};
		
		overflow-y: scroll;
		& > div {
		  overflow-y: inherit;
		}
		@media(max-width: 850px) {
			margin: 12px;
		}
	}
`

const ModalContent = styled(CardContent) <{ dark?: boolean }>`
	&& {
		background: ${({ dark }) => dark ? '#2c3e50' : 'white'};
		width: 800px;
		padding: 26px;
		flex-direction: column;
		display: block;
		z-index: 1;
		overflow-y: auto;
		@media (max-width: 849px) {
			max-width: Calc(100vw - 96px);
		}
		@media (max-width: 850px) {
			max-width: initial;
			position: static;
			top: 24px;
			left: 0px;
			right: 0px;
			bottom: 24px;
			width: initial;
			border-radius: 2px;
		}
	}
`
