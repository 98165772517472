import React from 'react'
import styled from 'styled-components'
import {
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	Button
} from '@material-ui/core'
import {
	useAdminPromosQuery,
	useAdminSetpromoCreditsMutation
} from '../../apollo/generated'
import Loading from '../ui/Loading'
import EditableText from '../ui/EditableText'
import ResponsiveModal from '../ui/ResponsiveModal'
import CreatePromoModal from '../modals/createPromoModal'

const AdminPromos = () => {
	const { data, loading, refetch } = useAdminPromosQuery()
	const [setCredits] = useAdminSetpromoCreditsMutation()
	const [modalOpen, setModalOpen] = React.useState(false)
	if (loading || !data || !data.adminPromos) {
		return <Loading />
	}

	return (
		<>
			<Paper style={{ margin: 20, padding: 12 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Code</TableCell>
							<TableCell>User Type</TableCell>
							<TableCell>Zip Codes</TableCell>
							<TableCell align="right">Credits</TableCell>
							<TableCell align="right">Expires At</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.adminPromos.map(row => (
							<TableRow key={row.id}>
								<TableCell component="th" scope="row">
									{row.code}
								</TableCell>
								<TableCell>{row.userType}</TableCell>
								<TableCell>{row.zipCodes.join(', ')}</TableCell>
								<TableCell align="right">
									<EditableText
										onChange={v =>
											setCredits({
												variables: { credits: parseInt(v), id: row.id }
											}).then(() => refetch())
										}
										title="Current Credits"
										value={row.creditsLeft}
									>
										{row.creditsLeft}/{row.startingCredits}
									</EditableText>
								</TableCell>

								<TableCell align="right">
									{!row.expiresAt
										? 'Never'
										: new Date(row.expiresAt).toLocaleDateString()}
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<Button
								style={{ marginTop: 8 }}
								onClick={() => setModalOpen(true)}
							>
								New Promo
							</Button>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
			<ResponsiveModal open={modalOpen} onClose={() => setModalOpen(false)}>
				<CreatePromoModal
					onClose={() => {
						setModalOpen(false)
						refetch()
					}}
				/>
			</ResponsiveModal>
		</>
	)
}

export default AdminPromos
