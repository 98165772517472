import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Charge, User, QueryOptions } from '../types';
import { UserFragmentFragmentDoc as UserFragment } from '../generated'

export const GET_CHARGES = gql`
	query GetCharges {
		charges {
			id
			status
            description
            trigger
            amount
            amountCurrency
            authDate
            captureDate
            redactedCard
            fullName
        }
        user { ...UserFragment }
	}
    ${UserFragment}
`;

export interface GetCharges {
    charges: Charge[]
    user?: User
}

export function useGetCharges(options?: QueryOptions) {
	return useQuery<GetCharges, {}>(GET_CHARGES, { fetchPolicy: 'network-only', ...options });
}
